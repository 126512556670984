import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

const NotFoundPage = () => {
  return (
    <Layout hide_contact_section>
      <Content
        title="Nie znaleziono strony"
        text="Przepraszamy, coś poszło nie tak."
        sub_text="Prosimy sprawdzić wpisany adres strony w przeglądarce. Jeśli adres jest poprawny, najwidoczniej taka podstrona nie znajduje się na naszej stronie."
      />
    </Layout>
  );
};

export const Head = () => <Seo title="Nie znaleziono strony | Brzegova" />;

export default NotFoundPage;
